<template>
    <div class="row">
        <Grades class="col-md-6" title="Domínio da tipologia" :grades="grades.structureAndContent" setMethod="setTypologyDomain" @setTypologyDomain="setTypologyDomain"/>
        <Grades class="col-md-6" title="Organização do texto" :grades="grades.structureAndContent" setMethod="setTextOrganization" @setTextOrganization="setTextOrganization"/>
        <Grades class="col-md-6" title="Desenvolvimento do tema" :grades="grades.structureAndContent" setMethod="setThemeDevelopment" @setThemeDevelopment="setThemeDevelopment"/>
        <Grades class="col-md-6" title="Qualidade do conteúdo" :grades="grades.structureAndContent" setMethod="setContentQuality" @setContentQuality="setContentQuality"/>
        <Grades class="col-md-6" title="Coesão" :grades="grades.structureAndContent" setMethod="setCohesion" @setCohesion="setCohesion"/>
        <Grades class="col-md-6" title="Investimento autoral" :grades="grades.structureAndContent" setMethod="setAuthorialInvestment" @setAuthorialInvestment="setAuthorialInvestment"/>

        <Grades class="col-md-12" title="Ortografia" :description="descriptions.orthography" :grades="grades.orthography" setMethod="setOrthography" @setOrthography="setOrthography"/>
        <Grades class="col-md-12" title="Semântica" :description="descriptions.semantics" :grades="grades.semantics" setMethod="setSemantics" @setSemantics="setSemantics"/>

        <Grades class="col-md-12" title="Pontuação" :description="descriptions.punctuation" :grades="grades.semantics" setMethod="setPunctuation" @setPunctuation="setPunctuation"/>
        <Grades class="col-md-12" title="Sintaxe e Morfossintaxe" :description="descriptions.syntaxMorphosyntax" :grades="grades.syntaxMorphosyntax" setMethod="setSyntaxMorphosyntax" @setSyntaxMorphosyntax="setSyntaxMorphosyntax"/>
    </div>
</template>

<script>
import Grades from './Grades.vue';

export default {

    name: 'Eniac',
    components: { Grades },
    data() {
        return {
            grades: {
                structureAndContent: [
                    { title: 0, value: 0.0 },
                    { title: 1, value: 0.5 },
                    { title: 2, value: 1.0 },
                    { title: 3, value: 1.5 },
                    { title: 4, value: 2.0 },
                    { title: 5, value: 2.5 },
                ],
                orthography: [
                    { title: "Até 2 erros",     value: 1.0 },
                    { title: "3 a 4 erros",     value: 0.5 },
                    { title: "5 erros ou mais", value: 0.0 },
                ],
                semantics: [
                    { title: "Não há erros",    value: 2.5 },
                    { title: "1 erro",          value: 2.0 },
                    { title: "2 a 3 erros",     value: 1.5 },
                    { title: "4 a 5 erros",     value: 1.0 },
                    { title: "6 a 7 erros",     value: 0.5 },
                    { title: "8 erros ou mais", value: 0.0 },
                ],

                punctuation: [
                    { title: "Não há erros",    value: 2.5 },
                    { title: "1 erro",          value: 2.0 },
                    { title: "2 a 3 erros",     value: 1.5 },
                    { title: "4 a 5 erros",     value: 1.0 },
                    { title: "6 a 7 erros",     value: 0.5 },
                    { title: "8 erros ou mais", value: 0.0 },
                ],

                syntaxMorphosyntax: [

                    { title: "Não há erros",       value: 4.0 },
                    { title: "1 erro",             value: 3.5 },
                    { title: "2 erros",            value: 3.0 },
                    { title: "3 erros",            value: 2.5 },
                    { title: "4 erros",            value: 2.0 },
                    { title: "5 erros",            value: 1.5 },
                    { title: "6 erros",            value: 1.0 },
                    { title: "7 erros",            value: 0.5 },
                    { title: "8 erros ou mais",    value: 0.0 },
                ]
            },
            descriptions: {
                orthography: "uso de maiúsculas e minúsculas, separação e junção de vocábulos, acentuação, aspas, uso de hífen, internetês, separação de sílabas, grafia das palavras, abreviações.",
                semantics: "nexos, retomada lexical, impropriedade de registro, afixos, coordenação, paralelismo vocabular, retomada pronominal, vocábulo de sentido amplo/impreciso, repetição de palavras e de expressões, redundância/obviedade, expressão/vocábulo inadequado, ambiguidade, pluralização de abstrações, uso de artigos.",
                punctuation: "adjunto adverbial deslocado, conjunções deslocadas, orações adverbiais, elementos intercalados, conjunções coordenativas, orações adjetivas, aposto, separação de termos essenciais e integrantes, enumeração, uso de dois pontos, de ponto e vírgula, de travessão e de parênteses.",
                syntaxMorphosyntax: "concordância nominal e verbal, regência nominal e verbal, crase, omissão de termos da oração, colocação indevida de pronomes átonos, uso indevidos de tempos verbais, problemas de estruturação do período (frase siamesa ou fragmentada, coordenação por subordinação, paralelismo sintático), ausência de complemento, mudança de pessoa gramatical."
            },
            typologyDomain: 0,
            textOrganization: 0,
            themeDevelopment: 0,
            contentQuality: 0,
            cohesion: 0,
            authorialInvestment: 0,
            orthography: 0,
            semantics: 0,
            punctuation: 0,
            syntaxMorphosyntax: 0,
        }
    },

    methods: {

        setTypologyDomain(value) {
            this.typologyDomain = value;
        },

        setTextOrganization(value) {
            this.textOrganization = value;
        },

        setThemeDevelopment(value) {
            this.themeDevelopment = value;
        },

        setContentQuality(value) {
            this.contentQuality = value;
        },

        setCohesion(value) {
            this.cohesion = value;
        },

        setAuthorialInvestment(value) {
            this.authorialInvestment = value;
        },

        setOrthography(value) {
            this.orthography = value;
        },

        setSemantics(value) {
            this.semantics = value;
        },

        setPunctuation(value) {
            this.punctuation = value;
        },

        setSyntaxMorphosyntax(value) {
            this.syntaxMorphosyntax = value;
        },

        calculateFinalGrade() {
            this.$parent.setFinalGrade(this.typologyDomain + this.textOrganization + this.themeDevelopment + this.contentQuality + this.cohesion + this.authorialInvestment + this.orthography + this.semantics + this.punctuation + this.syntaxMorphosyntax)
        },

        getCorrection() {
            return {
                typologyDomain: this.typologyDomain,
                textOrganization: this.textOrganization,
                themeDevelopment: this.themeDevelopment,
                contentQuality: this.contentQuality,
                cohesion: this.cohesion,
                authorialInvestment: this.authorialInvestment,
                orthography: this.orthography,
                semantics: this.semantics,
                punctuation: this.punctuation,
                syntaxMorphosyntax: this.syntaxMorphosyntax
            }
        }
    }
}
</script>

<style scoped>

    .ufrgs-grade-text {
        display: flex;
        justify-content: center;
        color: green;
        font-weight: bold;
        text-align: -webkit-center;
        font-size: -webkit-xxx-large;
    }

</style>