<template>
    <div class="card card-primary">
        <div class="card-header">
            <h3 class="card-title">Emoji</h3>
        </div>
        <div class="card-body text-center">
            <div class="btn-group-toggle" data-toggle="buttons">
                <label class="btn btn-outline-primary border-0" v-for="emoji in this.emojis" :key="emoji.id">
                    <input type="radio" name="options" id="option_a1" autocomplete="off" :value="emoji.id" v-model="selected">
                    <span v-html="emoji.code" class="emoji"></span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Emoji',
    data() {
        return {
            emojis: [
                { id: 1, code: '&#x1F929' },
                { id: 2, code: '&#x1F60D' },
                { id: 3, code: '&#x1F600' },
                { id: 4, code: '&#x1F60C' },
                { id: 5, code: '&#x1F60A' },
                { id: 6, code: '&#x1F60F' },
                { id: 7, code: '&#x1F612' },
                { id: 8, code: '&#x1F615' },
                { id: 9, code: '&#x1F61E' },
                { id: 9, code: '&#x1F629' },
            ],
            selected: 6
        }
    },
    methods: {
        getSelected() {
            return this.selected
        },

        setSelected(selected) {
            this.selected = selected
        }
    }
}
</script>

<style scoped>
    .emoji {
        width: 80px;
        height: 80px;
        font-size: 50px;
    }

    .emojis {
        margin: 0 auto;
        width: 70%;
    }
</style>