<template>
    <button class="btn btn-info mr-1" data-toggle="modal" data-target="#modal-student-history" @click="openHistorict()">Histórico do Aluno</button>
    <div class="modal fade" id="modal-student-history" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-student-history modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Histórico do Aluno</h4>
                </div>
                <div class="modal-body table-responsive">
                    <table class="table table-striped table-valign-middle">
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th>Nota</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="essay in essays" :key="essay.id">
                                <td>{{ new Date(essay.created_at).toLocaleString() }}</td>
                                <td>{{ essay.correction[0].nota }}</td>
                                <td>
                                    <button class="btn btn-sm btn-info"><font-awesome-icon icon="info" /> Visualizar</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="card-footer">
                    <Pagination @updateData="essays = $event" @isBusy="isBusy = $event" :url="url" ref="pagination"/>
                    <!-- <button type="button" class="btn btn-warning" data-dismiss="modal" @click="close">Fechar</button> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import Pagination from './Pagination.vue'
import { CORRECTION_STUDENT } from '../../constants/api'

export default {
  components: { Pagination },
    name: 'ModalStudentHistory',
    data() {
        return {
            essays: [],
            isLoaded: false,
            url: `${CORRECTION_STUDENT}/${this.$parent.essay.student_id}/historic`
        }
    },
    methods: {
        open() {
            $("#modal-student-history").show()
        },

        close() {

            $("#modal-student-history").hide()
        },
        openHistorict() {

            if(!this.isLoaded) {

                this.url = `${CORRECTION_STUDENT}/${this.$parent.essay.student_id}/historic`
                this.$refs.pagination.getPage(this.url)
            }
        }
    }
}
</script>
