<template>
    <div class="row">
        <Grades class="col-md-12" title="Critérios de plágio" :grades="grades" setMethod="setPlagiarismCriteria" @setPlagiarismCriteria="setPlagiarismCriteria"/>
        <Grades class="col-md-12" title="Conteúdo" :grades="grades" setMethod="setContent" @setContent="setContent"/>
        <Grades class="col-md-12" title="Coerência" :grades="grades" setMethod="setCoherence" @setCoherence="setCoherence"/>
        <Grades class="col-md-12" title="Interpessoalidade" :grades="grades" setMethod="setInterpersonalSkills" @setInterpersonalSkills="setInterpersonalSkills"/>
        <Grades class="col-md-12" title="Coerência com a proposta textual" :grades="grades" setMethod="setConsistencyTextualProposal" @setConsistencyTextualProposal="setConsistencyTextualProposal"/>
    </div>
</template>

<script>
import Grades from './Grades.vue';

export default {

    name: 'Eniac',
    components: { Grades },
    data() {
        return {
            grades: [
                { title: 0,  value: 0  },
                { title: 1,  value: 1  },
                { title: 2,  value: 2  },
                { title: 3,  value: 3  },
                { title: 4,  value: 4  },
                { title: 5,  value: 5  },
                { title: 6,  value: 6  },
                { title: 7,  value: 7  },
                { title: 8,  value: 8  },
                { title: 9,  value: 9  },
                { title: 10, value: 10 },
            ],
            plagiarism_criteria: 0,
            content: 0,
            coherence: 0,
            interpersonal_skills: 0,
            consistency_textual_proposal: 0
        }
    },

    methods: {

        setPlagiarismCriteria(value) {
            this.plagiarism_criteria = value;
        },

        setContent(value) {
            this.content = value;
        },

        setCoherence(value) {
            this.coherence = value;
        },

        setInterpersonalSkills(value) {
            this.interpersonal_skills = value;
        },

        setConsistencyTextualProposal(value) {
            this.consistency_textual_proposal = value;
        },

        calculateFinalGrade() {
            this.$parent.setFinalGrade(this.plagiarism_criteria + this.content + this.coherence + this.interpersonal_skills + this.consistency_textual_proposal)
        },

        correctionTaqe() {
            
        }

    }
}
</script>

<style scoped>

    .taqe-grade-text {
        display: flex;
        justify-content: center;
        color: green;
        font-weight: bold;
        text-align: -webkit-center;
        font-size: -webkit-xxx-large;
    }

</style>