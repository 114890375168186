<template>
    <div class="card bg-primary div-timer">
        <div class="d-flex align-items-center">
            <font-awesome-icon icon="clock" class="icon" />
            <div id="timer" class="timer">{{ time }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Timer',
    data() {
        return {
            seconds: 0,
            minutes: 0,
            hours: 0,
            time: '00:00:00'
        }
    },
    mounted() {
        setInterval(() => {
            this.seconds++
            if (this.seconds >= 60) {
                this.seconds = 0;
                this.minutes++;
                if (this.minutes >= 60) {
                    this.minutes = 0;
                    this.hours++;
                }
            }

            var zeroPad = (num, places) => String(num).padStart(places, '0')

            this.time = String.raw`${zeroPad(this.hours, 2)}:${zeroPad(this.minutes, 2)}:${zeroPad(this.seconds, 2)}`

        }, 1000);
    },
    methods: {

        getTime() {
            return this.time
        }

    }
}
</script>

<style scoped>
    .timer {
        padding: 5px;
    }

    .div-timer {
        margin-right: 5px;
        margin-left: 0px;
        margin-bottom: 0px;
        height: 38px;
    }

    .icon {
        margin-right: 5px;
        margin-left: 5px;
    }
</style>