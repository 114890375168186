<template>
    <div class="d-flex justify-content-center mb-3" v-if="isImage">
        <button class="btn btn-dark mr-1 btn-rotate" @click="turnLeft()">
            <font-awesome-icon icon="undo-alt" />
        </button>
        <button class="btn btn-dark ml-1 btn-rotate" @click="turnRight()">
            <font-awesome-icon icon="redo-alt" />
        </button>
    </div>
    <div class="card card-primary">
        <div class="ribbon-wrapper ribbon-xl" v-if="this.is_unreadable">
            <div class="ribbon bg-warning text-xl">Zerado</div>
        </div>
        <EssayImage ref="essayImage" v-if="isImage" :image="image" :essayCorrection="essayCorrection"/>
        <EssayText ref="essayText" v-else :text="essayCorrection" />
        <ModalCorrection ref="modalCorrection" :isImage="isImage" />
    </div>
</template>

<script>
import ModalCorrection from './ModalCorrection.vue'

import EssayImage from './EssayImage.vue';
import EssayText from './EssayText.vue';

import axios from "axios";
import { ESSAYS_CORRECT_INDEX } from '../../constants/api'

export default {

    name: 'Essay',
    props: ['isImage', 'essayCorrection', 'image'],
    components: { ModalCorrection, EssayImage, EssayText },
    data() {

        return {
            is_plagiarism: false,
            is_unreadable: false,
        }
    },

    methods: {

        markAsUnreadable() {

            this.is_plagiarism = false
            this.is_unreadable = !this.is_unreadable

        },

        markAsPlagiarism() {

            this.is_plagiarism = !this.is_plagiarism
            this.is_unreadable = false

        },

        turnLeft() {
            axios.get(`${ESSAYS_CORRECT_INDEX}/${this.$route.params.id}/turn-image-left`, { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } }).then(({ data }) => {
                this.$parent.essay.image = data
            })
        },

        turnRight() {
            axios.get(`${ESSAYS_CORRECT_INDEX}/${this.$route.params.id}/turn-image-right`, { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } }).then(({ data }) => {
                this.$parent.essay.image = data
            })
        },

    },
}
</script>

<style>

    .btn-rotate {
        width: 50px;
        border: none;
        background-color: rgba(0, 0, 0, 0.4);
    }

    #border-marking {
        border: 1px solid black;
        cursor: crosshair;
    }

    .correcao-remover {
        display: none;
    }

    .erro-ia {
        background-color: rgba(232,19,19,1);
        color: white;
    }

    .erro-comentario {
        background-color: rgba(0,123,255,1) !important;
        color: white;
    }

    .btn-comentario:hover {
        background-color: rgba(0,123,255,1);
        color: white
    }

    .btn-comentario:focus {
        background-color: rgba(0,123,255,1);
        color: white;
    }

    .mark {
        position: absolute;
        width: 1.5%;
        padding-top: 1.5%;
        opacity: 0.3;
    }

    .correction-text {
        border: 1px solid #aaa;
        width: 100%;
        height: 300px;
        overflow-x: auto;
        border-radius: 5px;
        padding: 5px;
    }

    .error-select {
        background-color: red;
        border-color: red;
    }
</style>
