<template>
    <div class="card card-primary">
        <div class="card-header">
            <h3 class="card-title">{{ competence.title }}</h3>
        </div>
        <div class="card-body">
            <p class="text-muted">{{ competence.description }}</p>
            <div v-if="competence.id == 5" class="form-group">
                <label>A Proposta respeita os direitos humanos? </label>
                <div class="form-group">
                    <div class="btn-group btn-group-toggle competence-grades" data-toggle="buttons">
                        <label class="btn btn-outline-success" :class="{ active : false === this.violatesHumanRights }" @click="violatesHumanRightsEvent">
                            <input type="radio" name="violatesHumanRights" autocomplete="off" :value="false" v-model="this.violatesHumanRights"> Sim
                        </label>
                        <label class="btn btn-outline-danger" :class="{ active : true === this.violatesHumanRights }" @click="dontViolatesHumanRights">
                            <input type="radio" name="violatesHumanRights" autocomplete="off" :value="true" v-model="this.violatesHumanRights"> Não
                        </label>
                    </div>
                </div>
            </div>
            <div class="form-group btn-group btn-group-toggle competence-grades" data-toggle="buttons">
                <label class="btn btn-outline-primary" v-for="rate in rates" :key="rate.id" :class="{ active : rate.rate === this.rate }" @click="changeRate(rate.rate)">
                    <input type="radio" autocomplete="off" v-model="this.rate" :value="rate.rate"> {{ rate.rate }}
                </label>
            </div>
            <div class="form-group">
                <label>Tags</label>
                <select :id="`tags-competence-${competence.id}`" class="form-control select2" multiple>
                </select>
            </div>
            <div class="form-group">
                <label>Comentário</label>
                <textarea class="comment form-control" rows="4" placeholder="" v-model="comment"></textarea>
            </div>
            <div class="form-group" id="show-missing-element" v-if="this.competence.id == 5 && this.hasMissingElements">
                <label>Selecione os elementos faltantes</label>
                <div class="btn-group btn-group-toggle d-flex align-items-stretch">
                    <div class="form-check form-check-inline">
                        <input type="checkbox" class="btn-check" value="Ação" id="element-1" v-model="this.missingElements">
                        <label class="btn btn-outline-primary" for="element-1">Ação</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="checkbox" class="btn-check" value="Agente" id="element-2" v-model="this.missingElements">
                        <label class="btn btn-outline-primary" for="element-2">Agente</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="btn-check" type="checkbox" value="Meios/modos" id="element-3" v-model="this.missingElements">
                        <label class="btn btn-outline-primary" for="element-3">Meios/modos</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="btn-check" type="checkbox" value="Efeito/Objetivo" id="element-4" v-model="this.missingElements">
                        <label class="btn btn-outline-primary" for="element-4">Efeito/Objetivo</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="btn-check" type="checkbox" value="Detalhamento de um dos elementos" id="element-5" v-model="this.missingElements">
                        <label class="btn btn-outline-primary" for="element-5">Detalhamento de um dos elementos</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import Toastr from "admin-lte/plugins/toastr/toastr.min.js"
import "admin-lte/plugins/toastr/toastr.min.css"
import axios from "axios";
import "admin-lte/plugins/select2/js/select2.min.js";
import { CORRECTION_COMPETENCE } from '../../constants/api'

export default {
    name: 'Competence',
    props: ["competence", "rates", "setMethod"],
    mounted() {
        $(document).ready(function() {
            $('.select2').select2({ width: '100%'});
        });

        this.rate = this.competence.rate
        this.comment = this.competence.comment

        if(!this.rate) {
            this.getTags()
        } else {
            this.loadTags()
        }
    },
    data() {
        return {
            rate: 0,
            selected: 2,
            comment: null,
            violatesHumanRights: false,
            hasMissingElements: false,
            missingElements: [],
            tagsMissingElements: [55, 56, 58, 59],
            amountMissingElements: {
                0 :  5,
                40:  4,
                80:  3,
                120: 2,
                160: 1,
                200: 0,
            },
            connectives: ['Também', 'bem como', 'assim como', 'como também ', 'como ainda', 'além disso', 'ainda', 'ademais', 'não só… mas também', 'não só… como também'],
        }
    },
    methods: {

        changeRate(rate) {
            this.violatesHumanRights = false
            this.getSelectedTags(rate)
        },

        loadTags() {
            axios.get(`${CORRECTION_COMPETENCE}/${this.competence.id}/tags`, { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } }).then(({ data }) => {

                var classSelector = $(`#tags-competence-${this.competence.id}`).html('');

                data.tags.forEach(c => {
                    classSelector.append($("<option></option>").attr("value", c.id).text(c.name));
                });

                axios.get(`${CORRECTION_COMPETENCE}/${this.competence.id}/rate/${this.rate}/tags`, { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } }).then(({ data }) => {

                    var tags = []
                    var selectedTags = []

                    for (let i = 0; i < data.length; i++) {
                        tags.push(data.tag);
                        selectedTags.push(data[i].tag)
                    }

                    this.setSelectedTags(selectedTags)
                })
            });
        },

        getTags() {

            axios.get(`${CORRECTION_COMPETENCE}/${this.competence.id}/tags`, { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } }).then(({ data }) => {

                var classSelector = $(`#tags-competence-${this.competence.id}`).html('');

                data.tags.forEach(c => {
                    classSelector.append($("<option></option>").attr("value", c.id).text(c.name));
                });

                if(this.rate) {
                    this.getSelectedTags(this.rate)
                }
            });
        },

        getTagsViolatesHumanRights() {

            axios.get(`${CORRECTION_COMPETENCE}/violates-human-rights`, { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } }).then(({ data }) => {
                this.comment = data[0].phrase
                this.setSelectedTags(data.tags)
            });
        },

        clearComment() {

            this.comment = ''

        },

        setSelectedTags(tags) {

            $(`#tags-competence-${this.competence.id}`).select2({width:'100%'}).val(tags).trigger('change')

            if(this.competence.id == 5) {
                let foundMissingElements = tags.find(item => this.tagsMissingElements.indexOf(parseInt(item)) > -1)

                if(foundMissingElements){
                    this.hasMissingElements = true

                } else {
                    this.hasMissingElements = false
                }
            }

            this.$parent.calculateFinalGrade()

        },

        getSelectedTags(rate) {

            axios.get(`${CORRECTION_COMPETENCE}/${this.competence.id}/rate/${rate}/tags`, { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } }).then(({ data }) => {

                var tags = []
                var selectedTags = []

                this.clearComment()

                for (let i = 0; i < data.length; i++) {

                    var conectivo = this.connectives[Math.floor(Math.random()*this.connectives.length)];
                    tags.push(data.tag);

                    var length = data.length-1;
                    selectedTags.push(data[i].tag)

                    if(!this.comment) {

                        if (i < length) {

                            this.comment += data[i].phrase+' '+conectivo

                        } else {

                            this.comment += data[i].phrase;

                        }

                    }

                }

                this.setSelectedTags(selectedTags)

            })


        },

        violatesHumanRightsEvent() {
            this.violatesHumanRights = true
            this.getSelectedTags(this.rate)
        },

        dontViolatesHumanRights() {
            this.getTagsViolatesHumanRights()
            this.rate = 0
            this.$parent.calculateFinalGrade()
        },

        getRate() {
            return this.rate
        },

        setRate(rate) {
            this.rate = rate
        },

        getComment() {
            return this.comment
        },

        setComment(comment) {
            this.comment = comment
        },

        getViolatesHumanRights() {
            return this.violatesHumanRights
        },

        setViolatesHumanRights(humanRights) {
            this.violatesHumanRights = humanRights
        },

        checkMissingElements() {

            let comment = this.hasMissingElements ? this.comment + " Elementos faltantes | "+ this.missingElements.join(', ') : this.comment

            if(this.missingElements.length != this.amountMissingElements[this.rate]) {
                Toastr.warning(`Você selecionou a nota ${this.rate} para a competência 5 e ela exige que você selecione ${this.amountMissingElements[this.rate]} elemento(s) faltante(s). Você selecionou ${this.missingElements.length} elemento(s).`, "")
                return false
            }

            this.comment = comment
            return true
        }
    }
}
</script>

<style scoped>
    .competence-grades > label.btn {
        width:60px;
    }

    .comment {
        height: 175px;
        overflow-x: auto;
        border-radius: 5px;
        padding: 15px;
    }
</style>