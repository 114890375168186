<template>
    <div>
        <div class="info-box bg-light">
            <div class="info-box-content">
                <span class="info-box-text text-center text-muted">{{ title }}</span>
                <span class="info-box-number text-center text-muted mb-0">{{ description }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InfoBox',
    props: ['title', 'description']
}
</script>