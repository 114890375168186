<template>
    <div id="redacao" class="card-body" @click="addMarking" v-html="text"></div>
</template>

<script>
import $ from "jquery";
import "admin-lte/plugins/popper/popper.min.js";

export default {
    name: 'Essay',
    props: ['text'],
    components: { },
    data() {
        return {
            selectedString: null,
        }
    },
    mounted() {

        $('.content').bind("DOMSubtreeModified", function() {

            $('div#redacao span').popover({
                trigger: 'hover',
                placement: 'top',
                html: true,
                title: function() {
                    return $(this).attr('data-title')
                },
                content: function () {
                    return "<p>" + $(this).attr('content') + "<br><b>Sugestão:</b><br>" + $(this).attr('suggestions') + "</p>";
                }
            });

        });

        $(document).on('click', 'div#redacao span', function (event) {
            event.stopPropagation();
            document.querySelector('#redacao').__vueParentComponent.ctx.editMarking($(this))
        });

    },
    methods: {

        addMarking() {

            var selection = window.getSelection();

            if(selection)
            {
                selection = selection.getRangeAt(0);
            }

            var selectedText = selection.extractContents();

            if (selectedText.textContent) {
                var span = document.createElement("span");
                span.className = 'before-correction';
                span.appendChild(selectedText);
                selection.insertNode(span);
            }

            this.selectedString = window.getSelection().toString();

            if(this.selectedString.length > 0){
                $('.btn-'+ this.$parent.$refs.modalCorrection.selectedTag).addClass('erro-' + this.$parent.$refs.modalCorrection.selectedTag);
                this.$parent.$refs.modalCorrection.open()
            }

        },

        editMarking(element) {
            this.$parent.$refs.modalCorrection.selectedSpan = element;
            this.$parent.$refs.modalCorrection.selectedSpan.addClass('before-correction');
            this.$parent.$refs.modalCorrection.selectedString = this.$parent.$refs.modalCorrection.selectedSpan.text();
            $('.correction-text').text(this.$parent.$refs.modalCorrection.selectedSpan.attr('data-content'))
            this.$parent.$refs.modalCorrection.selectedTag = this.$parent.$refs.modalCorrection.selectedSpan.attr('data-tag')
            $('.btn-correction[data-tag='+this.$parent.$refs.modalCorrection.selectedTag+']').addClass('erro-' + this.$parent.$refs.modalCorrection.selectedTag)

            this.$parent.$refs.modalCorrection.editMarking()
            this.$parent.$refs.modalCorrection.open()
        },
    },
}
</script>

<style scoped>

    #redacao {
        text-align: justify;
        font-size: 1.2em;
    }

    /* 
    .correcao-remover {
        display: none;
    }

    .erro-ia {
        background-color: rgba(232,19,19,1);
        color: white;
    }

    .correction-text {
        border: 1px solid #aaa;
        width: 100%;
        height: 300px;
        overflow-x: auto;
        border-radius: 5px;
        padding: 5px;
    }

    .error-select {
        background-color: red;
        border-color: red;
    }    */
</style>
