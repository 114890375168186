<template>
    <div class="row">
        <Grades class="col-md-4" title="Respeito à norma culta" :grades="grades" setMethod="setRespectCulturedNorm" @setRespectCulturedNorm="setRespectCulturedNorm"/>
        <Grades class="col-md-4" title="Atendimento ao tema" :grades="grades" setMethod="setThemeCompliance" @setThemeCompliance="setThemeCompliance"/>
        <Grades class="col-md-4" title="Atendimento ao gênero textual" :grades="grades" setMethod="setTextualGenreCompliance" @setTextualGenreCompliance="setTextualGenreCompliance"/>
        <Grades class="col-md-4" title="Coesão e coerência" :grades="grades" setMethod="setCohesionCoherence" @setCohesionCoherence="setCohesionCoherence"/>
        <Grades class="col-md-4" title="Repertório sociocultural" :grades="grades" setMethod="setSocioculturalRepertory" @setSocioculturalRepertory="setSocioculturalRepertory"/>
    </div>
</template>

<script>
import Grades from './Grades.vue';

export default {
    
    name: 'Eniac',
    components: { Grades },
    data() {
        return {
            grades: [
                { title: 0,   value: 0   },
                { title: 50,  value: 50  },
                { title: 100, value: 100 },
                { title: 150, value: 150 },
                { title: 200, value: 200 },
            ],
            respectCulturedNorm: 0,
            themeCompliance: 0,
            textualGenreCompliance: 0,
            cohesionCoherence: 0,
            socioculturalRepertory: 0
        }
    },

    methods: {

        setRespectCulturedNorm(value) {
            this.respectCulturedNorm = value;
        },

        setThemeCompliance(value) {
            this.themeCompliance = value;
        },

        setTextualGenreCompliance(value) {
            this.textualGenreCompliance = value;
        },

        setCohesionCoherence(value) {
            this.cohesionCoherence = value;
        },

        setSocioculturalRepertory(value) {
            this.socioculturalRepertory = value;
        },

        calculateFinalGrade() {
            this.$parent.setFinalGrade(this.respectCulturedNorm + this.themeCompliance + this.textualGenreCompliance + this.cohesionCoherence + this.socioculturalRepertory)
        },

        getCorrection() {
            return {
                respectCulturedNorm: this.respectCulturedNorm,
                themeCompliance: this.themeCompliance,
                textualGenreCompliance: this.textualGenreCompliance,
                cohesionCoherence: this.cohesionCoherence,
                socioculturalRepertory: this.socioculturalRepertory,
            }
        }

    }
}
</script>

<style scoped>

    .taqe-grade-text {
        display: flex;
        justify-content: center;
        color: green;
        font-weight: bold;
        text-align: -webkit-center;
        font-size: -webkit-xxx-large;
    }

</style>