<template>
    <div id="img-redacao" class="card-body p-0">
        <img :src="image" usemap="#points" class="maphilighted" id="imagem" draggable="false" :style="borderMarking.showBorder ? 'cursor: crosshair' : ''" @mousedown="mouseDownEssay" @mousemove="mouseMoveEssay" @mouseup="mouseUpEssay">
        <map name="points" id="points" @click="editMarking" v-html="essayCorrection"></map>
        <div v-if="borderMarking.showBorder" id="border-marking" :style="`position: absolute; top: ${borderMarking.absolutePercentagePositionY}%; left: ${borderMarking.absolutePercentagePositionX}%; width: ${borderMarking.width}px; height: ${borderMarking.height}px;`" @mouseup="mouseUpEssay" @mousemove="mouseMoveEssay"></div>
    </div>
</template>

<script>
import $ from "jquery";
import "admin-lte/plugins/popper/popper.min.js";

export default {
    name: 'EssayImage',
    components: { },
    props: ['image', 'essayCorrection'],
    data() {
        return {
            borderMarking : {
                absolutePercentagePositionX: 0,
                absolutePercentagePositionY: 0,
                initialPositionX: 0,
                initialPositionY: 0,
                finalPositionX: 0,
                finalPositionY: 0,
                width: 0,
                height: 0,
                percentageWidth: 0,
                percentageHeight: 0,
                showBorder: false
            },
        }
    },
    mounted() {
        $('.content').bind("DOMSubtreeModified", function() {

            $('div#img-redacao span').popover({
                trigger: 'hover',
                placement: 'top',
                html: true,
                title: function() {
                    return $(this).attr('data-tag')
                },
                content: function () {
                    return "<p>" + $(this).attr('content') + "<br> Sugestão:" + $(this).attr('suggestions') + "</p>";
                }
            });
        });
    },
    methods: {

        mouseDownEssay(event) {

            this.borderMarking.absolutePercentagePositionX = this.calculateAbsolutePercentagePosition(event.offsetX, event.target.clientWidth)
            this.borderMarking.absolutePercentagePositionY = this.calculateAbsolutePercentagePosition(event.offsetY, event.target.clientHeight)

            this.borderMarking.initialPositionX = event.clientX;
            this.borderMarking.initialPositionY = event.clientY;

            this.borderMarking.showBorder = true

        },

        calculateAbsolutePercentagePosition(offset, length) {

            return ((offset / length) * 100).toPrecision(4)

        },

        mouseMoveEssay(event) {

            this.borderMarking.finalPositionX = event.clientX
            this.borderMarking.finalPositionY = event.clientY

            this.borderMarking.width = this.borderMarking.finalPositionX - this.borderMarking.initialPositionX;
            this.borderMarking.height = this.borderMarking.finalPositionY - this.borderMarking.initialPositionY;

            this.borderMarking.percentageWidth = this.calculateAbsolutePercentagePosition(this.borderMarking.width, event.target.clientWidth)
            this.borderMarking.percentageHeight = this.calculateAbsolutePercentagePosition(this.borderMarking.height, event.target.clientHeight)
        },

        mouseUpEssay(event) {

            this.borderMarking.width = (((event.offsetX) / event.target.clientWidth) * 100).toPrecision(4)
            this.borderMarking.height = (((event.offsetY) / event.target.clientHeight) * 100).toPrecision(4)

            if(this.borderMarking.width == 0) {
                this.borderMarking.width = 2;
            }

            if(this.borderMarking.height == 0) {
                this.borderMarking.height = 2;
            }

            this.borderMarking.showBorder = false

            this.$parent.$refs.modalCorrection.open()
        },

        editMarking(event) {

            this.$parent.$refs.modalCorrection.selectedSpan = $(event.target)
            this.$parent.$refs.modalCorrection.selectedSpan.addClass('before-correction')

            $('.correction-text').text(this.$parent.$refs.modalCorrection.selectedSpan.attr('data-content'))
            this.$parent.$refs.modalCorrection.selectedTag = this.$parent.$refs.modalCorrection.selectedSpan.attr('data-tag')

            $('.btn-correction[data-tag='+this.$parent.$refs.modalCorrection.selectedTag+']').addClass('erro-' + this.$parent.$refs.modalCorrection.selectedTag)
            this.$parent.$refs.modalCorrection.editMarking()
            this.$parent.$refs.modalCorrection.open()
        },

    },
}
</script>

<style scoped>

    #imagem {
        width: 100%;
        cursor: crosshair;
        position: relative;
        margin: 0 auto;
    }

    /* #border-marking {
        border: 1px solid black;
        cursor: crosshair;
    }

    .correcao-remover {
        display: none;
    }

    .erro-ia {
        background-color: rgba(232,19,19,1);
        color: white;
    }

    .erro-comentario {
        background-color: rgba(0,123,255,1) !important;
        color: white;
    }

    .btn-comentario:hover {
        background-color: rgba(0,123,255,1);
        color: white
    }

    .btn-comentario:focus {
        background-color: rgba(0,123,255,1);
        color: white;
    }

    .mark {
        position: absolute;
        width: 1.5%;
        padding-top: 1.5%;
        opacity: 0.3;
    }

    .correction-text {
        border: 1px solid #aaa;
        width: 100%;
        height: 300px;
        overflow-x: auto;
        border-radius: 5px;
        padding: 5px;
    }

    .error-select {
        background-color: red;
        border-color: red;
    }    */
</style>
