<template>
    <div class="card card-success">
        <div class="card-header">
            <h3 class="card-title">Nota Final</h3>
        </div>
        <div class="card-body text-center">
            <p id="nota-final" class="text-green text-bold">{{ grade }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FinalGrade',
    props: ['grade'],
}
</script>

<style scoped>

    #nota-final {
        display: flex;
        justify-content: center;
        font-weight: bold;
        text-align: -webkit-center;
        font-size: -webkit-xxx-large;
    }

</style>