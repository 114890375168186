<template>
    <div class="content-wrapper">
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">Corrigir</h1>
                    </div>
                </div>
            </div>
        </div>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <InfoBox class="col-md-3" title="Código" :description="this.essay.id" />
                    <InfoBox class="col-md-3" title="Hora de envio" :description="new Date(this.essay.created_at).toLocaleString()" />
                    <!-- <InfoBox class="col-md-3" title="Corrigida por" :description="Daniel Boso" /> -->
                    <InfoBox class="col-md-3" title="Tipo de Correção" :description="this.essay.correction_type.name" />
                    <InfoBox class="col-md-3" title="Aluno / Empresa" :description="this.essay.student_id ? this.essay.student.name : this.essay.employer.name" />
                    <InfoBox class="col-md-9" title="Tema escolhido" description="Tema" />
                    <InfoBox class="col-md-3" title="Prova" description="UFRGS" />
                </div>
                <a class="btn btn-warning mr-1 btn-block mb-3" href="/correction/test/" target="_blank">Edital UFRGS <span class="pull-right">clique para ver</span></a>
                <div class="d-flex justify-content-center mb-3">
                    <ModalStudentHistory />
                    <ModalTheme />
                    <div class="btn-group">
                        <button type="button" class="btn btn-warning dropdown-toggle dropdown-icon" data-toggle="dropdown">Zerar <span class="sr-only">Toggle Dropdown</span></button>
                        <div class="dropdown-menu" role="menu">
                            <button class="dropdown-item" @click="unreadable">Ilegível</button>
                            <button class="dropdown-item" @click="plagiarism">Plágio</button>
                            <button class="dropdown-item" @click="insufficientText">Texto Insuficiente</button>
                            <button class="dropdown-item" @click="fea">FEA</button>
                            <button class="dropdown-item" @click="copy">Cópia</button>
                            <button class="dropdown-item" @click="avoidanceSubject">Fuga ao tema</button>
                            <button class="dropdown-item" @click="nonComplianceTextualType">Não atendimento ao tipo textual</button>
                            <button class="dropdown-item" @click="disconnectedPart">Parte desconectada</button>
                        </div>
                    </div>
                </div>
                <Essay ref="essay" :isImage="isImage" :essayCorrection="this.essay.correction.length !=0 ? this.essay.correction[0].essay_correction : essay.text" :image="essay.image"/>
                <Enem ref="enem" v-if="mirror.isEnemCorrection" :correction="this.essay.correction ? this.essay.correction[0] : null"/>
                <Eniac ref="eniac" v-if="mirror.isExamEniacCorrection"/>
                <Fuvest ref="fuvest" v-if="mirror.isFuvestCorrection"/>
                <Taqe ref="taqe" v-if="mirror.isTaqeCorrection"/>
                <UFRGS ref="UFRGS" v-if="mirror.isUFRGSCorrection"/>
                <GeneralComment ref="generalComment" :minimumCharacters="minimumCharactersGeneralComment" :generalComment="this.essay.correction.length !=0 ? this.essay.correction[0].general_comment : ''"/>
                <Emoji ref="emoji"/>
                <FinalGrade ref="finalGrade" :grade="finalGrade"/>
                <div class="d-flex justify-content-center pb-3">
                    <button class="btn btn-success" @click="store">Salvar</button>
                </div>
            </div>
            <div class="back-to-top d-flex justify-content-center">
                <Timer ref="timer"/>
                <a id="back-to-top" href="#" class="btn btn-primary" role="button" aria-label="Scroll to top">
                    <font-awesome-icon icon="chevron-up"/>
                </a>
            </div>
        </section>
    </div>
</template>

<script>
import Toastr from "admin-lte/plugins/toastr/toastr.min.js"
import "admin-lte/plugins/toastr/toastr.min.css"
import Timer from './Timer.vue'
import InfoBox from '../partials/InfoBox.vue'
import GeneralComment from '../partials/GeneralComment.vue'
import Emoji from '../partials/Emoji.vue'
import FinalGrade from '../partials/FinalGrade.vue'
import Essay from '../partials/Essay.vue'
import ModalStudentHistory from '../partials/ModalStudentHistory.vue'
import ModalTheme from '../partials/ModalTheme.vue'
import Enem from '../mirrors/Enem.vue'
import Eniac from '../mirrors/Eniac.vue'
import Fuvest from '../mirrors/Fuvest.vue'
import Taqe from '../mirrors/Taqe.vue'
import UFRGS from '../mirrors/UFRGS.vue'
import api from '../../services/api'
import { ESSAYS_CORRECT_INDEX } from '../../constants/api'

export default {
    components: { Timer, InfoBox, GeneralComment, Emoji, FinalGrade, Essay, ModalStudentHistory, ModalTheme, Enem, Eniac, Fuvest, Taqe, UFRGS },
    data() {
        return {
            finalGrade: 0,
            essay: {
                correction_type : '',
                student_id : '', 
                student : {
                    name : ''
                },
                employer : {
                    name : ''
                },
                motivational_text : {
                    theme : {
                        name : ''
                    }
                },
                correction: []
            },
            isImage: false,
            image: '',
            minimumCharactersGeneralComment: 100,
            mirror: {
                isEnemCorrection: false,
                isExamEniacCorrection: false,
                isFuvestCorrection: false,
                isTaqeCorrection: false,
                isUFRGSCorrection: false,
            },
            zered: {
                unreadable: {
                    competenceComments: "Seu texto foi zerado por estar ilegível.",
                    generalComment: "Oi, tudo bem? Infelizmente, sua redação está ilegível. Certifique-se de que a qualidade da imagem está adequada e envie seu texto novamente. Bons estudos!"
                },
                plagiarism: {
                    competenceComments: "Redação zerada por plágio.",
                    generalComment: "Oi, tudo bem? Infelizmente, sua redação foi zerada por ser uma cópia deste texto: "
                },
                insufficientText: {
                    competenceComments: "Redação zerada por não conter o mínimo de linhas exigido.",
                    generalComment: "Oi, tudo bem? Infelizmente, sua redação foi zerada por texto insuficiente."
                },
                fea: {
                    competenceComments: "Texto zerado por forma elementar de anulação. Como exemplos de casos assim, temos: desenhos, emojis, números isolados do corpo do texto, texto em língua estrangeira, etc.",
                    generalComment: "Oi, tudo bem? Infelizmente, seu texto foi zerado por FEA - formas elementares de anulação. Isso significa que você descumpriu orientações elementares da situação de produção prevista na proposta de redação."
                },
                copy: {
                    competenceComments: "Texto zerado por ser uma cópia dos textos motivadores.",
                    generalComment: "Oi, tudo bem? Infelizmente, sua redação foi zerada por ser uma cópia dos textos motivadores. Lembre-se de que eles devem servir apenas para motivar a discussão e a reflexão sobre o tema."
                },
                avoidanceSubject: {
                    competenceComments: "Texto zerado por fuga ao tema.",
                    generalComment: "Oi, tudo bem? Infelizmente, sua redação foi zerada por fuga ao tema. Isso significa que você não contemplou os elementos temáticos da proposta de redação, por isso sugerimos que dê mais atenção a ela antes de começar a escrever."
                },
                nonComplianceTextualType: {
                    competenceComments: "Texto zerado por não atendimento ao tipo textual.",
                    generalComment: "Oi, tudo bem? Infelizmente, sua redação foi zerada por não atendimento ao tipo textual exigido pela proposta de redação. Sugerimos que revise as exigências da tipologia textual em questão antes de começar a escrever."
                },
                disconnectedPart: {
                    competenceComments: "Texto zerado por conter parte desconectada. Como exemplos de casos assim, temos: ofensas, zombarias, impropérios, identificação no corpo do texto, oração ou mensagem religiosa, trecho sobre outro assunto, etc.",
                    generalComment: "Oi, tudo bem? Infelizmente, sua produção textual foi zerada por ter parte desconectada. Isso significa que, nela, há conteúdo que atenta contra a situação comunicativa em que o texto está inserido e/ou está desconectado do tema e/ou do projeto de texto/da discussão proposta na sua redação."
                }
            }
        }
    },
    created() {
        api.get(`${ESSAYS_CORRECT_INDEX}/${this.$route.params.id}`).then(({ data }) => {
            this.essay = data.essay
            this.mirror = data.mirror

            if(this.essay.image) {
                this.isImage = true
            }
        })
    },

    methods: {

        zering(messages) {
            this.$refs.enem.$refs.competence1.rate = 0;
            this.$refs.enem.$refs.competence1.comment = messages.competenceComments;
            this.$refs.enem.$refs.competence1.loadTags()
            this.$refs.enem.$refs.competence2.rate = 0;
            this.$refs.enem.$refs.competence2.comment = messages.competenceComments;
            this.$refs.enem.$refs.competence2.loadTags()
            this.$refs.enem.$refs.competence3.rate = 0;
            this.$refs.enem.$refs.competence3.comment = messages.competenceComments;
            this.$refs.enem.$refs.competence3.loadTags()
            this.$refs.enem.$refs.competence4.rate = 0;
            this.$refs.enem.$refs.competence4.comment = messages.competenceComments;
            this.$refs.enem.$refs.competence4.loadTags()
            this.$refs.enem.$refs.competence5.rate = 0;
            this.$refs.enem.$refs.competence5.comment = messages.competenceComments;
            this.$refs.enem.$refs.competence5.loadTags()
            this.$refs.generalComment.comment = messages.generalComment;
        },

        unreadable() {
            this.$refs.essay.markAsUnreadable()
            this.zering(this.zered.unreadable)
            
        },

        plagiarism() {
            this.$refs.essay.markAsPlagiarism()
            this.zering(this.zered.plagiarism)
        },

        setFinalGrade(grade) {
            this.finalGrade = grade
        },

        insufficientText() {
            this.zering(this.zered.insufficientText)
        },

        fea() {
            this.zering(this.zered.fea)
        },

        copy() {
            this.zering(this.zered.copy)
        },

        avoidanceSubject() {
            this.zering(this.zered.avoidanceSubject)
        },

        nonComplianceTextualType() {
            this.zering(this.zered.nonComplianceTextualType)
        },

        disconnectedPart() {
            this.zering(this.zered.disconnectedPart)
        },

        store() {

            var post = {}
            post.generalComment = this.$refs.generalComment.getComment()

            post.essayId = this.essay.id
            post.essayType = this.essay.correction_type.id

            if(this.isImage) {

                post.essayCorrection = document.getElementById('points').innerHTML

            } else {

                post.essayText = document.getElementById('redacao').innerText
                post.essayCorrection = document.getElementById('redacao').innerHTML

            }

            post.time = this.$refs.timer.getTime()
            post.isImage = this.isImage

            if(this.mirror.isEnemCorrection) {

                post.correctionEnem = this.$refs.enem.getCorrection()

                if(!post.correctionEnem) {
                    return
                }

            }

            if(this.mirror.isUFRGSCorrection) {

                post.correctionUFRGS = this.$refs.UFRGS.getCorrection()

            }

            if(this.mirror.isExamEniacCorrection) {

                post.correctionEniac = this.$refs.eniac.getCorrection()

            }

            if(this.mirror.isFuvestCorrection) {

                post.correctionFuvest = this.$refs.fuvest.getCorrection()

            }

            if(this.mirror.isTaqeCorrection) {

                post.correctionTaqe = this.$refs.taqe.getCorrection()

            }

            if(post.generalComment.length < this.minimumCharactersGeneralComment) {
                Toastr.warning(`Não esqueça de caprichar no comentário geral. Ele deve ter no mínimo ${this.minimumCharactersGeneralComment} caracteres.`)
                return
            }

            post.finalGrade = this.finalGrade
            post.emoji = this.$refs.emoji.getSelected()

            api.post(`${ESSAYS_CORRECT_INDEX}/${this.$route.params.id}`, post).then(({ data }) => {
                Toastr.success(data.message[0], "")
                this.$router.push('/corrigir')
            })
            .catch(function (error) {

                if (error.response) {

                    if(error.response.status === 400) {

                        if(error.response.data.warning) {
                            Toastr.warning(error.response.data.warning[0].message, error.response.data.warning[0].title)
                        }

                    } else {
                        Toastr.error(error.response.data.message[0], "")
                    }
                }
            })
        },

    }
}
</script>