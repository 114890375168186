<template>
    <div class="row">
        <Grades class="col-md-4" title="Desenvolvimento do tema e organização do texto" :grades="grades.developmentAndOrganization" setMethod="setDevelopmentAndOrganization" @setDevelopmentAndOrganization="setDevelopmentAndOrganization"/>
        <Grades class="col-md-4" title="Coerência dos argumentos e articulação das partes do texto" :grades="grades.coherenceAndArticulation" setMethod="setCoherenceAndArticulation" @setCoherenceAndArticulation="setCoherenceAndArticulation"/>
        <Grades class="col-md-4" title="Correção gramatical e adequação vocabular" :grades="grades.grammaticalCorrection" setMethod="setGrammaticalCorrection" @setGrammaticalCorrection="setGrammaticalCorrection"/>
    </div>
</template>

<script>
import Grades from './Grades.vue';

export default {

    name: 'Eniac',
    components: { Grades },
    data() {
        return {
            grades: {
                developmentAndOrganization: [
                    { title: 0, value: 0 },
                    { title: 1, value: 1 },
                    { title: 2, value: 2 },
                    { title: 3, value: 3 },
                    { title: 4, value: 4 },
                ],
                coherenceAndArticulation: [
                    { title: 0, value: 0 },
                    { title: 1, value: 1 },
                    { title: 2, value: 2 },
                    { title: 3, value: 3 },
                ],
                grammaticalCorrection: [
                    { title: 0, value: 0 },
                    { title: 1, value: 1 },
                    { title: 2, value: 2 },
                    { title: 3, value: 3 },
                ],
            },
            developmentAndOrganization: 0,
            coherenceAndArticulation: 0,
            grammaticalCorrection: 0,
        }
    },

    methods: {

        setDevelopmentAndOrganization(value) {
            this.developmentAndOrganization = value;
        },

        setCoherenceAndArticulation(value) {
            this.coherenceAndArticulation = value;
        },

        setGrammaticalCorrection(value) {
            this.grammaticalCorrection = value;
        },

        calculateFinalGrade() {
            this.$parent.setFinalGrade(this.developmentAndOrganization + this.coherenceAndArticulation + this.grammaticalCorrection)
        },

        getCorrection() {
            return {
                developmentAndOrganization: this.developmentAndOrganization,
                coherenceAndArticulation: this.coherenceAndArticulation,
                grammaticalCorrection: this.grammaticalCorrection,
            }
        }

    }
}
</script>

<style scoped>
    .fuvest-grade-text {
        display: flex;
        justify-content: center;
        color: green;
        font-weight: bold;
        text-align: -webkit-center;
        font-size: -webkit-xxx-large;
    }
</style>