<template>
    <div class="card card-primary">
        <div class="card-header">
            <h3 class="card-title">Comentário Geral</h3>
        </div>
        <div class="card-body">
            <div class="form-group">
                <div class="d-flex justify-content-between">
                    <p class="text-muted">São necessários no mínimo {{ minimumCharacters }} caracteres.</p>
                    <p class="text-muted">{{ this.length }} / {{ minimumCharacters }} caracteres</p>
                </div>
                <textarea class="form-control" :class="this.length >= minimumCharacters ? 'is-valid' : 'is-invalid'" rows="4" placeholder="" v-model="comment"></textarea>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GeneralComment',
    props: ['minimumCharacters', 'generalComment'],
    mounted() {
        this.comment = this.generalComment
    },
    data() {
        return {
            comment: '',
            length: 0
        }
    },
    watch: {
        comment() {
            this.length = this.comment.length
        }
    },
    methods: {

        getComment() {
            return this.comment
        },

        setComment(comment) {
            this.comment = comment
        }

    }
}
</script>