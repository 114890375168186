<template>
    <div>
        <div class="card">
            <div class="card-header border-0">
                <div class="d-flex justify-content-between">
                    <h3 class="card-title"><b>{{ title }}</b></h3>
                </div>
            </div>
            <div class="card-body">
                <p v-if="description" class="text-muted">{{ description }}</p>
                <div class="btn-group btn-group-toggle d-flex justify-content-center" data-toggle="buttons">
                    <label class="btn btn-outline-primary" v-for="grade in grades" :key="grade">
                        <input type="radio" autocomplete="off" :value="grade.value" v-model="selectedGrade"> {{ grade.title }}
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

    name: 'Grades',
    props: ['cardSize', 'title', 'description', 'grades', 'setMethod'],
    data() {
        return {
            selectedGrade: 0
        }
    },
    watch: {

        selectedGrade() {
            this.$emit(this.setMethod, this.selectedGrade)
            this.$parent.calculateFinalGrade()
        }
    },

}
</script>

<style scoped>

    label.btn {
        width:60px;
    }

</style>
