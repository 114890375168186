<template>
    <button type="button" class="btn btn-success mr-1" data-toggle="modal" data-target="#modal-theme" @click="loadTheme()">Tema</button>
    <div class="modal fade" id="modal-theme">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Tema</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div id="accordion">
                        <div class="card card-defult" v-for="text in theme.motivational_texts" :key="text.id">
                            <div class="card-header">
                                <h4 class="card-title w-100">
                                    <a class="d-block w-100 collapsed" data-toggle="collapse" :href="`#text-${text.id}`" aria-expanded="false">{{ text.title }}</a>
                                </h4>
                            </div>
                            <div :id="`text-${text.id}`" class="collapse" data-parent="#accordion">
                                <div class="card-body" v-html="text.motivacional_text"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer justify-content-center">
                    <button type="button" class="btn btn-warning" data-dismiss="modal">Fechar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { GET_THEME } from '../../constants/api'

export default {
    data() {
        return {
            theme: {},
            isLoaded: false,
        }
    },
    methods: {
        loadTheme() {
            if(!this.isLoaded) {
                axios.get(`${GET_THEME}/${this.$parent.essay.motivational_text.theme_id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } }).then(({ data }) => {
                    this.theme = data
                    this.isLoaded = true
                })
            }
        }
    }
}
</script>