<template>
    <div>
        <Competence v-for="competence in competences" :key="competence.id" :competence="competence" :rates="rates" :ref="`competence${competence.id}`"/>
    </div>
</template>

<script>
import Competence from '../partials/Competence.vue'

import Toastr from "admin-lte/plugins/toastr/toastr.min.js"
import "admin-lte/plugins/toastr/toastr.min.css"

export default {

    components: { Competence },
    name: 'Competences',
    props: ['correction'],
    created() {

        if(this.correction) {

            this.competences[0].rate = this.correction.competence_1_rate
            this.competences[0].comment = this.correction.competence_1_comment

            this.competences[1].rate = this.correction.competence_2_rate
            this.competences[1].comment = this.correction.competence_2_comment

            this.competences[2].rate = this.correction.competence_3_rate
            this.competences[2].comment = this.correction.competence_3_comment

            this.competences[3].rate = this.correction.competence_4_rate
            this.competences[3].comment = this.correction.competence_4_comment

            this.competences[4].rate = this.correction.competence_5_rate
            this.competences[4].comment = this.correction.competence_5_comment
            this.competences[4].violateHumanRights = this.correction.violates_human_rights == 0 ? true : false

        }


    },
    mounted() {

        this.calculateFinalGrade()

    },
    data() {
        return {
            competences: [
                {
                    id: 1,
                    title: "Competência 1",
                    description: "Demonstrar domínio da modalidade escrita formal da língua portuguesa.",
                    rate: null,
                    comment: null,
                },
                {
                    id: 2,
                    title: "Competência 2",
                    description: "Compreender a proposta de redação e aplicar conceitos das várias áreas de conhecimento para desenvolver o tema, dentro dos limites estruturais .do texto dissertativo-argumentativo em prosa.",
                    rate: null,
                    comment: null
                },
                {
                    id: 3,
                    title: "Competência 3",
                    description: "Selecionar, relacionar, organizar e interpretar informações, fatos, opiniões e argumentos em defesa de um ponto de vista.",
                    rate: null,
                    comment: null
                },
                {
                    id: 4,
                    title: "Competência 4",
                    description: "Demonstrar conhecimento dos mecanismos linguísticos necessários para a construção da argumentação.",
                    rate: null,
                    comment: null
                },
                {
                    id: 5,
                    title: "Competência 5",
                    description: "Elaborar proposta de intervenção para o problema abordado, respeitando os direitos humanos.",
                    rate: null,
                    comment: null,
                    violateHumanRights: false
                },
            ],
            rates: [
                { id: 1, rate:   0, },
                { id: 2, rate:  40, },
                { id: 3, rate:  80, },
                { id: 4, rate: 120, },
                { id: 5, rate: 160, },
                { id: 6, rate: 200, },
            ]
        }
    },
    
    methods: {

        calculateFinalGrade() {

            var grade = 0

            for (let i = 0; i < this.competences.length; i++) {
                grade += this.$refs[`competence${ this.competences[i].id }`].rate
            }

            this.$parent.setFinalGrade(grade)
        },

        checkMissingElements() {

            return this.$refs.this.$refs.competence5.checkMissingElements()

        },

        getCorrection() {

            if (!((this.$refs.competence1.getRate()) || (this.$refs.competence2.getRate()) || (this.$refs.competence3.getRate()) || (this.$refs.competence4.getRate()) || (this.$refs.competence5.getRate()))) 
            {
                Toastr.warning("Você precisa atribuir uma nota para todas as competências.", "")
                return false;
            }

            if(!this.$refs.competence5.checkMissingElements()) {
                return false
            }

            return  {
                competence1Rate: this.$refs.competence1.getRate(),
                competence1Comment: this.$refs.competence1.getComment(),
                competence2Rate: this.$refs.competence2.getRate(),
                competence2Comment: this.$refs.competence2.getComment(),
                competence3Rate: this.$refs.competence3.getRate(),
                competence3Comment: this.$refs.competence3.getComment(),
                competence4Rate: this.$refs.competence4.getRate(),
                competence4Comment: this.$refs.competence4.getComment(),
                competence5Rate: this.$refs.competence5.getRate(),
                competence5Comment: this.$refs.competence5.getComment(),
                violatesHumanRights: this.$refs.competence5.getViolatesHumanRights(),
            }
        },
    }
}
</script>