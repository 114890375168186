<template>
    <div class="modal fade" id="modal-correction" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-correction modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ title }}</h4>
                </div>
                <div class="modal-body">
                    <div class="tags">
                        <div class="btn-group btn-group-toggle d-flex justify-content-center" data-toggle="buttons">
                            <label class="btn btn-outline-primary mr-1 mt-1">
                                <input type="radio" value="comentario" v-model="this.selectedTag" checked> Comentário
                            </label>
                            <label class="btn mr-1 mt-1" :class="`btn-outline-${ note.name.slugfy() }`" v-for="note in notes" :key="note.id" @click="loadNote(note.id, note.name.slugfy())">
                                <input type="radio" :value="note.name.slugfy()" v-model="this.selectedTag"> {{ note.name }}
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 ">
                            <div class="alert alert-warning alert-dismissible mt-3 mb-0">
                                <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                                <font-awesome-icon icon="exclamation-triangle" /> Selecione uma tag para Lorem ipsum dolor sit.
                            </div>
                        </div>
                        <div id="card-tags" class="col-md-6 mt-3" style="display: none;">
                            <div  class="card card-secondary">
                                <div class="card-header">
                                    <h3 class="card-title">Tags</h3>
                                </div>
                                <div class="card-body">
                                    <div :id="`note-tag-${ note.name.slugfy() }`" class="note-tag" v-for="note in notes" :key="note.id" style="display: none;">
                                        <div class="" v-for="tag in note.tags" :key="tag.id" @click="loadComment(tag.id)">
                                            <input class="btn-check" type="radio" :name="`note-tags-${note.id}`" :id="`input-tag-${ tag.id }`" :value="tag.id">
                                            <label class="btn btn-sm btn-outline-primary nohover tag-label" :for="`input-tag-${ tag.id }`"> {{ tag.name }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="card-comment" class="col-md-12 mt-3 ">
                            <div class="card card-secondary">
                                <div class="card-header">
                                    <h3 class="card-title">Comentário</h3>
                                </div>
                                <div class="card-body p-0">
                                    <textarea id="correction-text" class="form-control" rows="5" v-model="comment"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer d-flex justify-content-between">
                    <button type="button" class="btn btn-warning d-flex pull-left" data-dismiss="modal" @click="close">Fechar</button>
                    <button type="button" class="btn btn-danger pull-right" @click="removeMarking" v-if="isEditing">remover</button>
                    <button type="button" class="btn btn-success pull-right" @click="saveMarking">Salvar marcação</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import "admin-lte/plugins/select2/js/select2.min.js";
import axios from "axios";
import Toastr from "admin-lte/plugins/toastr/toastr.min.js"
import "admin-lte/plugins/toastr/toastr.min.css"
import '../../utils/slugfy.js'
import { CORRECTION_NOTES, CORRECTION_TAG } from '../../constants/api'

export default {
    name: 'ModalCorrection',
    props: ['isImage'],
    data() {
        return {
            title: "Inserir Marcação",
            saveSelectionMethod: null,
            selectedTag: "comentario",
            selectedTitle: "Comentário",
            selectedSpan: null,
            correctionRemoveButton: false,
            selectedTagId: null,
            isEditing: false,
            comment: "",
            notes: [],
            slugTitles: {}
        }
    },
    created() {
        axios.get(`${CORRECTION_NOTES}`, { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } }).then(({ data }) => {
            this.notes = data
            this.createSlugTitles()
            this.createNotesStyle()
        });

        $(document).ready(function() {
            $('.select2').select2({ width:'100%' });
        });
    },
    mounted() {

        if (!String.prototype.slugfy) {

            String.prototype.slugfy = function () {

                return this.toString().toLowerCase()
                .replace(/[àÀáÁâÂãäÄÅåª]+/g, 'a')       // Special Characters #1
                .replace(/[èÈéÉêÊëË]+/g, 'e')           // Special Characters #2
                .replace(/[ìÌíÍîÎïÏ]+/g, 'i')           // Special Characters #3
                .replace(/[òÒóÓôÔõÕöÖº]+/g, 'o')        // Special Characters #4
                .replace(/[ùÙúÚûÛüÜ]+/g, 'u')           // Special Characters #5
                .replace(/[ýÝÿŸ]+/g, 'y')               // Special Characters #6
                .replace(/[ñÑ]+/g, 'n')                 // Special Characters #7
                .replace(/[çÇ]+/g, 'c')                 // Special Characters #8
                .replace(/[ß]+/g, 'ss')                 // Special Characters #9
                .replace(/[Ææ]+/g, 'ae')                // Special Characters #10
                .replace(/[Øøœ]+/g, 'oe')               // Special Characters #11
                .replace(/[%]+/g, 'pct')                // Special Characters #12
                .replace(/\s+/g, '-')                   // Replace spaces with -
                // .replace(/[^\w\-]+/g, '')               // Remove all non-word chars
                // .replace(/\-\-+/g, '-')                 // Replace multiple - with single -
                // .replace(/^-+/, '')                     // Trim - from start of text
                .replace(/-+$/, '');                    // Trim - from end of text

            };
        }
    },
    watch: {
        selectedTag(newValue) {
            this.selectedTitle = this.slugTitles[newValue]

            this.clearComment()

            if(newValue == 'comentario') {

                $("#card-comment").removeClass('pl-0').removeClass('col-md-6').addClass('col-md-12')
                $("#card-tags").hide()

            } else {

                $("#card-comment").removeClass('col-md-12').addClass('pl-0 col-md-6')
                $("#card-tags").show()
            }

            $('div[id^=note-tag-]').hide();
            $('#note-tag-'+newValue).show();
        },

        selectedTagId(newValue) {
            this.loadComment(newValue)
            console.log(this.selectedTagId)
        }
    },
    methods: {

         open() {
            $('#modal-correction').modal('show')
        },

        close() {
            $('#modal-correction').modal('hide')
            this.hideEditMarking()
        },

        saveMarking() {

            if(this.selectedTag) {

                if(this.comment) {

                    if(this.isImage) {

                        this.correctionImage()

                    } else {

                        this.correctionText()

                    }

                    this.clearComment()

                } else {

                    Toastr.warning('Comentário vazio.')

                }

            } else {

                Toastr.warning('Marcar uma tag é obrigatório.')

            }

        },

        removeMarking() {

            if(this.isImage) {

                this.removeImageMarking()

            } else {

                this.removeTextMarking()

            }

        },

       removeImageMarking() {

            $(this.selectedSpan).remove();
            this.clearMarking()

       },

       removeTextMarking() {

            var html = $('div#redacao').html();
            $('div#redacao').html(html.replace(this.selectedSpan.prop('outerHTML'), this.selectedSpan.text()));
            this.clearMarking()

       },

       clearMarking() {

            this.selectedTag = 'comentario';
            this.selectedTitle = this.slugTitles[this.selectedTag];
            this.selectedSpan = null;
            this.$parent.$refs.essayText.selectedString = null; 
            $('.btn-tag button').removeClass('erro-ia erro-comentario erro-erro-gramatica erro-convencao-de-escrita erro-precisao-vocabular erro-escolha-de-registro');
            this.clearComment()
            this.close()

       },

        correctionImage() {

            var area = document.createElement("span")
            area.classList.add("mark")
            area.setAttribute("data-tag", this.selectedTag)
            area.setAttribute("data-title", this.selectedTitle)
            area.setAttribute("data-content", this.comment)
            area.classList.remove("erro-ia", "erro-comentario", "erro-erro-gramatica", "erro-convencao-de-escrita", "erro-precisao-vocabular", "erro-escolha-de-registro")
            area.classList.add(`erro-${this.selectedTag}`)
            area.style.top = `${this.$parent.$refs.essayImage.borderMarking.absolutePercentagePositionY}%`
            area.style.left = `${this.$parent.$refs.essayImage.borderMarking.absolutePercentagePositionX}%`
            area.style.width = `${this.$parent.$refs.essayImage.borderMarking.percentageWidth}%`
            area.style.height = `${this.$parent.$refs.essayImage.borderMarking.percentageHeight}%`

            document.getElementById('points').append(area)

            this.close()

        },

        correctionText() {

            $('.before-correction')
            .attr({'data-tag' : this.selectedTag})
            .attr({'data-title' : this.selectedTitle})
            .attr({'data-content' : this.comment})
            .removeClass('erro-ia erro-comentario erro-erro-gramatica erro-convencao-de-escrita erro-precisao-vocabular erro-escolha-de-registro')
            .removeClass('before-correction')
            .addClass('erro-' + this.selectedTag);

            this.close()

        },

        editMarking() {
            this.isEditing = true;
            this.title = "Editar Marcação"
        },

        hideEditMarking() {
            this.isEditing = false;
            this.title = "Inserir Marcação"
        },

        clearComment() {
            this.comment = ""
        },

        selectTag() {
            this.selectedTitle = this.slugTitles[this.selectedTag]
        },

        createSlugTitles() {

            this.slugTitles['comentario'] = 'Comentário'

            for (let i = 0; i < this.notes.length; i++) {
                this.slugTitles[this.notes[i].name.slugfy()] = this.notes[i].name
            }
        },

        createNotesStyle() {
            let css = ``;

            for (let i = 0; i < this.notes.length; i++) {

                css += `.btn-outline-${this.notes[i].name.slugfy()}{ color: ${this.notes[i].background_color}; border-color: ${this.notes[i].background_color};} `;
                css += `.btn-outline-${this.notes[i].name.slugfy()}:not(:disabled):not(.disabled).active, .btn-outline-${this.notes[i].name.slugfy()}:not(:disabled):not(.disabled):active, .show>.btn-outline-${this.notes[i].name.slugfy()}.dropdown-toggle { color: ${this.notes[0].text_color}; background-color: ${this.notes[i].background_color}; border-color: ${this.notes[i].background_color};} `
                css += `.btn-outline-${this.notes[i].name.slugfy()}:hover { color: ${this.notes[0].text_color}; background-color: ${this.notes[i].background_color}; border-color: ${this.notes[i].background_color};} `
                css += `.erro-${this.notes[i].name.slugfy()}{ color: #ffffff; background-color: ${this.notes[i].background_color};} `;
            }

            this.styleTag = document.createElement("style");
            this.styleTag.appendChild(document.createTextNode(css));
            document.head.appendChild(this.styleTag);
        },

        loadComment(tagId) {

            axios.get(`${CORRECTION_TAG}/${tagId}/phrase`, { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } }).then(({ data }) => {

                this.comment = data.phrase
            });

        },

    }
}
</script>

<style scoped>

    .tags {
        text-align: center;
    }

    .erro-comentario {
        background-color: rgba(0,123,255,1) !important;
        color: white !important;
    }

    .erro-ia {
        background-color: rgba(232,19,19,1) !important;
        color: white !important;
    }

    .tag-label {
        width: 100%;
    }

    .note-tag {
        height: 220px;
        min-height: 220px;
        overflow: auto;
    }

    #card-comment {
        height: 250px;
        min-height: 250px;
        overflow: auto;
    }

</style>
